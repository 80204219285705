import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";

const Page = ({ data }) => {
  const faqs = [
    {
      question: "WHAT IS A REVERSE MORTGAGE? WHY SHOULD I CONSIDER ONE?",
      answer: (
        <>
          <p>
            Reverse mortgages are designed for homeowners who are 62 or older
            and have considerable equity. With a reverse mortgage, you borrow
            against that equity and get cash. You can use the money to
            supplement your income, help pay for healthcare expenses, or use it
            for any other purpose.
          </p>
          <p>A reverse mortgage works like this:</p>
          <ul className="list-disc pl-6">
            <li>
              You have at least 50% equity in your home, or it’s paid off You
              apply for a reverse mortgage loan program
            </li>
            <li>A lender checks your credit and reviews property details</li>
            <li>
              If approved, the loan is funded and you stop making mortgage
              payments (you continue to pay the property taxes and homeowners’
              insurance, plus take care of basic home maintenance and repairs)
            </li>
            <li>
              You get proceeds in your choice of a lump sum, line of credit, or
              periodic annuity payments
            </li>
            <li>
              The loan balance becomes due when you pass away or move out of the
              home
            </li>
          </ul>
        </>
      ),
    },
    {
      question:
        "WHAT’S THE DIFFERENCE BETWEEN A REVERSE MORTGAGE AND A TRADITIONAL MORTGAGE?",
      answer: (
        <>
          <p>Here’s how the two differ:</p>
          <p className="text-bold">Traditional Mortgage</p>
          <ul className="list-disc pl-6">
            <li>
              Loan amount issued in a lump sum and is used to buy or refinance a
              home
            </li>
            <li>Loan repaid in monthly installments</li>
            <li>Property is considered collateral</li>
          </ul>
          <p className="text-bold">Reverse Mortgage</p>
          <ul className="list-disc pl-6">
            <li>
              Borrower gets either a lump sum, line of credit, or regular
              payments made to them
            </li>
            <li>
              Loan repaid when the homeowner passes away, sells the house, or
              moves out
            </li>
            <li>Loan granted against the equity in a home</li>
          </ul>
        </>
      ),
    },
    {
      question: "HOW MUCH DOES A REVERSE MORTGAGE COST?",
      answer: (
        <>
          <p>
            The costs can vary depending on the loan and lender. Here are some
            costs that are typically associated with a reverse mortgage loan:
          </p>
          <ul className="list-disc pl-6">
            <li>
              A fee to cover the counseling from a HUD-approved mortgage
              counselor
            </li>
            <li>Origination fees (cannot exceed $6,000)</li>
            <li>
              Closing costs such as an appraisal, title search, inspection,
              recording fees, etc.
            </li>
            <li>Initial mortgage insurance premium</li>
          </ul>
          <p>There are also ongoing costs, which include:</p>
          <ul className="list-disc pl-6">
            <li>Interest</li>
            <li>Servicing fees</li>
            <li>Annual mortgage insurance premium</li>
            <li>Homeowners insurance</li>
            <li>Other types of insurance, such as flood insurance</li>
            <li>Property taxes</li>
          </ul>
        </>
      ),
    },
    {
      question: "WHAT TYPES OF HOMES QUALIFY?",
      answer: (
        <>
          <p>
            Does your home fit the program? It likely does because all of the
            following types of properties are eligible. The main point to
            remember is that your home must be in good condition.
          </p>
          <ul className="list-disc pl-6">
            <li>Single-family home</li>
            <li>2-4 unit properties</li>
            <li>Manufactured homes</li>
            <li>Condos</li>
            <li>Townhouses</li>
          </ul>
        </>
      ),
    },

    {
      question: "WHAT IF I HAVE AN EXISTING MORTGAGE?",
      answer: (
        <>
          <p>
            You can pay off your existing mortgage with the proceeds of your
            reverse mortgage. For example, if you owe $200,000 on your existing
            mortgage and qualify for a $250,000 reverse mortgage, that would be
            enough to pay off your existing loan and leave you with $50,000.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Reverse Mortgage Loan Pasadena | Accel Mortgage Brokers"
        description="Looking for Pasadena's leading reverse mortgage broker? Contact us today to help you with your reverse mortgage needs to achieve your goals."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-24 md:pt-6">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Reverse Mortgage: Get Paid For Your Equity</h1>
              <p>
                A reverse mortgage is an option available to seniors 62 and
                older where a lender pays you instead of you paying them. You
                sell the lender equity in your California home, and they give
                you a lump sum, line of credit, or make payments to you.
              </p>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/9.0 Self Employed/1.0 Hero.jpg"
                loading="eager"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-16 md:mb-20">
            <h2>The Advantages of a Reverse Mortgage Loan</h2>
          </header>
          <div className="grid gap-x-10 gap-y-16 md:grid-cols-2 lg:gap-x-20">
            <div className="flex space-x-5">
              <div className="number-text-stroke">1</div>
              <div>
                <h3 className="heading-four mb-2">
                  No Monthly Mortgage To Pay
                </h3>
                <p className="mb-0">
                  You no longer pay your monthly mortgage payment—you can take
                  it right out of your budget.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">2</div>
              <div>
                <h3 className="heading-four mb-2">No Taxes Due</h3>
                <p className="mb-0">
                  The money you receive is considered by the IRS to be “loan
                  proceeds,” which makes it non-taxable.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">3</div>
              <div>
                <h3 className="heading-four mb-2">Get Extra Money</h3>
                <p className="mb-0">
                  Use the money you receive however you like. Pay off debt, fund
                  medical costs, or put it in the bank.
                </p>
              </div>
            </div>

            <div className="flex space-x-5">
              <div className="number-text-stroke">4</div>
              <div>
                <h3 className="heading-four mb-2">
                  Keep Your Government Benefits
                </h3>
                <p className="mb-0">
                  If you’re on Social Security or have Medicare, for example,
                  there’s no impact on your benefits.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h2>Am I Eligible?</h2>
              <p>Here’s how you qualify:</p>
              <ul className="styled-list-arrow">
                <li>62 years old or older</li>
                <li>You live in the home</li>
                <li>You have at least 50% equity (or your home is paid off)</li>
                <li>Your home is in good shape</li>
                <li>
                  You agree to receive HUD counseling that ensures you
                  understand the terms and associated costs
                </li>
              </ul>
              <div className="flex flex-wrap items-center space-y-6 md:flex-nowrap md:space-y-0 md:space-x-4">
                <ButtonSolid
                  href="/request-rates/"
                  text="Get a Rate Quote"
                  className="w-full md:w-auto"
                />
                <ButtonSolid
                  href="https://markdarling.floify.com/apply-now"
                  text="Get Pre-Approved"
                  outboundLink={true}
                  altStyle={2}
                  className="w-full md:w-auto"
                />
              </div>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/2.0 Conventional Loans/Reverse Mortgage.jpg"
                loading="lazy"
                width={560}
                className="z-0 rounded-6xl"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-8 md:mb-10">
            <h2>Frequently Asked Questions</h2>
          </header>
          <Accordion
            allowZeroExpanded={true}
            className="border-t border-gray-700/20"
          >
            {faqs.map((faq, i) => {
              return (
                <div key={i}>
                  <AccordionItem
                    uuid={i}
                    className="border-b border-gray-700/20 py-6 md:py-8"
                  >
                    <AccordionItemButton className="flex justify-between space-x-10 focus:outline-none md:space-x-20">
                      <AccordionItemState>
                        {(state) => {
                          return (
                            <>
                              <p className="heading-five mb-0">
                                {faq.question}
                              </p>

                              <i
                                className={`far ${
                                  state.expanded
                                    ? "fa-chevron-up"
                                    : "fa-chevron-down"
                                } text-base text-gray-700/50`}
                              ></i>
                            </>
                          );
                        }}
                      </AccordionItemState>
                    </AccordionItemButton>
                    <AccordionItemPanel className="pt-6 pr-10 md:pr-20">
                      {faq.answer}
                    </AccordionItemPanel>
                  </AccordionItem>
                </div>
              );
            })}
          </Accordion>
        </div>
      </section>

      <Resources />
      <Testimonials />
      <ValueProps />
      <RecentBlogPosts />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Homepage.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Homepage.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
